import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Preus.css';
import Footer from "../../layouts/public/Footer";
import Calendari from "../../components/elements/Calendari";

function Privacitat(props) {

	
    return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
					<Typography variant="h2" className="Preus-title">{props.masia?.privacitat_titol}</Typography>
					<div className="Preus-separator"></div>
					<Box className="Preus-content">
                        
						<Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>{props.masia?.privacitat_subtitol}</Typography>
						
					</Box>
				</Box>
                <Box className='Preus-white'>
                    
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' dangerouslySetInnerHTML={{__html: props.masia?.privacitat_content}}></Typography>
                            
                        </Box>
                    </Box>
                </Box>
			</Box>
		</Page>
	);
}

export default Privacitat;