import React from "react";
import { Navigate } from "react-router-dom";
import AdminLayout from "./layouts/admin";
import PublicLayout from "./layouts/public";
import HomeAdmin from "./views/admin/home/HomeAdmin";
import LoginView from "./views/admin/login/LoginView";
import Arribar from "./views/public/Arribar";
import Avis from "./views/public/Avis";
import Casa from "./views/public/Casa";
import Disponibilitat from "./views/public/Disponibilitat";
import Entorn from "./views/public/Entorn";
import Galeria from "./views/public/Galeria";
import Home from "./views/public/Home";
import Preus from "./views/public/Preus";
import Privacitat from "./views/public/Privacitat";
import CasaEdit from "./views/admin/Masia/CasaEdit";
import EntornEdit from "./views/admin/Entorn/EntornEdit";
import ArribarEdit from "./views/admin/Arribar/ArribarEdit";
import PreusEdit from "./views/admin/Reserves/PreusEdit";
import DisponibilitatEdit from "./views/admin/Contacte/DisponibilitatEdit";
import GaleriaEdit from "./views/admin/Galeria/GaleriaEdit";
import GestionarFotos from "./views/admin/Galeria/GestionarFotos";
import AddCaracteristica from "./views/admin/Masia/AddCaracteristica";
import AddCaracteristicaItem from "./views/admin/Masia/AddCaracteristicaItem";
import AddEspai from "./views/admin/Masia/AddEspai";
import AddEntorn from "./views/admin/Entorn/AddEntorn";
import AddCondicio from "./views/admin/Reserves/AddCondicio";
import AddCondicioItem from "./views/admin/Reserves/AddCondicioItem";
import PortadaEdit from "./views/admin/Portada/PortadaEdit";
import Rutes from "./views/public/Rutes";
import RutesEdit from "./views/admin/Rutes/RutesEdit";
import AddRuta from "./views/admin/Rutes/AddRuta";
import PoliticaCookies from "./views/public/PoliticaCookies";
import PrivacitatEdit from "./views/admin/PPrivacitat/PrivacitatEdit";
import AvisEdit from "./views/admin/PAvisLegal/AvisEdit";
import CookiesEdit from "./views/admin/PCookies/CookiesEdit";

const routes = (logged, isMobile, hideMenu, setHideMenu, idioma, setIdioma, idiomes, masia, type, setType, folder, setFolder, name, setName, id, setId, label, setLabel, endPoint, setEndPoint, initialValue, setInitialValue, setOpenModal, setOpenDeleteModal, actualitzar, setActualitzar) => [
	{
		path: "/admin",
		element: logged ? <AdminLayout /> : <Navigate to="/login" />,
		children: [
			{
				path: "/admin/dash",
				element: <HomeAdmin title="Home" />,
			},
			{ path: "/admin/portada", element: <PortadaEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/masia", element: <CasaEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/entorn", element: <EntornEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/arribar", element: <ArribarEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/reserves", element: <PreusEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/contacte", element: <DisponibilitatEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal} /> },
			{ path: "/admin/galeria", element: <GaleriaEdit actualitzar={actualitzar} setActualitzar={setActualitzar} isMobile={isMobile}  hideMenu={hideMenu} setHideMenu={setHideMenu} masia={masia} idioma={idioma} f_setIdioma={setIdioma} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal} /> },
			{ path: "/admin/galeria/:id", element: <GestionarFotos actualitzar={actualitzar} setActualitzar={setActualitzar} isMobile={isMobile}  hideMenu={hideMenu} setHideMenu={setHideMenu} masia={masia} idioma={idioma} f_setIdioma={setIdioma} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal} /> },
			{ path: "/admin/masia/caracteristica/:id/nou", element: <AddCaracteristica isMobile={isMobile} actualitzar={actualitzar} setActualitzar={setActualitzar}/> },
			{ path: "/admin/masia/caracteristica/:id/item/nou", element: <AddCaracteristicaItem isMobile={isMobile} actualitzar={actualitzar} setActualitzar={setActualitzar}/> },
			{ path: "/admin/masia/espai/:id/nou", element: <AddEspai isMobile={isMobile} actualitzar={actualitzar} setActualitzar={setActualitzar}/> },
			{ path: "/admin/entorn/:id/nou", element: <AddEntorn isMobile={isMobile} actualitzar={actualitzar} setActualitzar={setActualitzar}/> },
			{ path: "/admin/reserves/condicio/:id/nou", element: <AddCondicio isMobile={isMobile} actualitzar={actualitzar} setActualitzar={setActualitzar}/> },
			{ path: "/admin/reserves/condicio/:id/item/nou", element: <AddCondicioItem isMobile={isMobile} actualitzar={actualitzar} setActualitzar={setActualitzar}/> },
			{ path: "/admin/portada/imatges/:id", element: <GestionarFotos actualitzar={actualitzar} setActualitzar={setActualitzar} isMobile={isMobile}  hideMenu={hideMenu} setHideMenu={setHideMenu} masia={masia} idioma={idioma} f_setIdioma={setIdioma} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal} /> },
			{ path: "/admin/rutes", element: <RutesEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/rutes/:id/:tipus/nou", element:<AddRuta isMobile={isMobile} actualitzar={actualitzar} setActualitzar={setActualitzar}/>  },
			{ path: "/admin/privacitat", element: <PrivacitatEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/avis-legal", element: <AvisEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },
			{ path: "/admin/cookies", element: <CookiesEdit masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes} type={type} setType={setType} folder={folder} setFolder={setFolder} name={name} setName={setName} id={id} setId={setId}label={label} setLabel={setLabel} endPoint={endPoint} setEndPoint={setEndPoint} initialValue={initialValue} setInitialValue={setInitialValue} setOpenModal={setOpenModal} setOpenDeleteModal={setOpenDeleteModal}/> },



		],
	},
	{
		path: "/",
		element: <PublicLayout isMobile={isMobile} hideMenu={hideMenu} setHideMenu={setHideMenu} idioma={idioma} f_setIdioma={setIdioma} masia={masia} idiomes={idiomes}/>,
		children: [
			{
				path: "/login",
				element: !logged ? (
					<LoginView />
				) : (
					<Navigate to="/admin/dash" />
				),
			},
			{ path: "/", element: <Home masia={masia}/> },
            { path: "/la-masia", element: <Casa isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes}/> },
            { path: "/entorn", element: <Entorn isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes}/> },
            { path: "/com-arribar", element: <Arribar isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma}/> },
            { path: "/contacte", element: <Disponibilitat isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma}/> },
            { path: "/rutes", element: <Rutes isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma} idiomes={idiomes}/> },
            { path: "/reserves", element: <Preus isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma}/> },
			{ path: "/galeria", element: <Galeria isMobile={isMobile}  hideMenu={hideMenu} setHideMenu={setHideMenu} masia={masia} idioma={idioma} f_setIdioma={setIdioma}/> },
			{ path: "/politica-privacitat", element: <Privacitat  isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma}/> },
			{ path: "/avis-legal", element: <Avis  isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma}/> },
			{ path: "/politica-cookies", element: <PoliticaCookies  isMobile={isMobile} masia={masia} idioma={idioma} f_setIdioma={setIdioma}/> },
		],
	},
];

export default routes;
