import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import '../../../css/views/public/Home.css';
import '../../../css/layouts/public/Menu.css';
import image1 from '../../../assets/img/fotos/30.jpg';
import image2 from '../../../assets/img/fotos/31.jpg';
import image3 from '../../../assets/img/fotos/32.jpg';
import image4 from '../../../assets/img/fotos/33.jpg';
import image5 from '../../../assets/img/fotos/34.jpg';
import { useNavigate } from 'react-router';
import HomeButton from "../../../components/elements/HomeButton";
import PinatellPortada from "../../../components/elements/PinatellPortada";
import InstagramIcon from '@mui/icons-material/Instagram';
import GoogleIcon from '@mui/icons-material/Google';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Idioma from "../../../components/elements/Idioma";
import EditIcon from '@mui/icons-material/Edit';
import ButtonAdmin from '../ButtonAdmin';
import Edit from "../Edit";
import Page from "../../../components/layouts/Page";
import '../../../css/views/public/Home.css';



const PrivacitatEdit = (props) => {


    return (
		<Page title="El Pinatell">

            <div className="Home-idiomes" style={{top: '15%'}}>
                <Idioma selected={props.idioma} f_setSelected={props.f_setIdioma} idiomes={props.idiomes}/>
            </div>
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
                    <Edit 
                        endPoint='actualitzarMasia' 
                        initialValue={props.masia?.privacitat_titol} 
                        setInitialValue={props.setInitialValue} 
                        name={'privacitat_titol'} 
                        setName={props.setName} 
                        setEndPoint={props.setEndPoint} 
                        color='#fff' 
                        setOpenModal={props.setOpenModal} 
                        type={'link'} 
                        setType={props.setType} 
                        label='Titol Privacitat' 
                        setLabel={props.setLabel} 
                        id={props.masia?.id} 
                        setId={props.setId} 
                        folder={'masia'} 
                        setFolder={props.setFolder}
                    />
					<Typography variant="h2" className="Preus-title">{props.masia?.privacitat_titol}</Typography>
					<div className="Preus-separator"></div>
                    <Edit 
                        endPoint='actualitzarMasia' 
                        initialValue={props.masia?.privacitat_subtitol} 
                        setInitialValue={props.setInitialValue} 
                        name={'privacitat_subtitol'} 
                        setName={props.setName} 
                        setEndPoint={props.setEndPoint} 
                        color='#fff' 
                        setOpenModal={props.setOpenModal} 
                        type={'link'} 
                        setType={props.setType} 
                        label='Subtitol Privacitat' 
                        setLabel={props.setLabel} 
                        id={props.masia?.id} 
                        setId={props.setId} 
                        folder={'masia'} 
                        setFolder={props.setFolder}
                    />
					<Box className="Preus-content">
                        
						<Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>{props.masia?.privacitat_subtitol}</Typography>
						
					</Box>
				</Box>
                <Box className='Preus-white'>
                    
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            <Edit 
                                endPoint='actualitzarMasia' 
                                initialValue={props.masia?.privacitat_content} 
                                setInitialValue={props.setInitialValue} 
                                name={'privacitat_content'} setName={props.setName} 
                                setEndPoint={props.setEndPoint} 
                                color='#000' 
                                setOpenModal={props.setOpenModal} 
                                type={'text'} 
                                setType={props.setType} 
                                label='Imatge' 
                                setLabel={props.setLabel} 
                                id={props.masia?.id} 
                                setId={props.setId} 
                                folder={'masia'} 
                                setFolder={props.setFolder}
                            />
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' dangerouslySetInnerHTML={{__html: props.masia?.privacitat_content}}></Typography>
                            
                        </Box>
                    </Box>
                </Box>
			</Box>
		</Page>
	);
}

export default PrivacitatEdit;
