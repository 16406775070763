import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Preus.css';
import Footer from "../../layouts/public/Footer";
import Calendari from "../../components/elements/Calendari";

function Avis(props) {

	
    return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
					<Typography variant="h2" className="Preus-title">{props.masia?.avis_titol}</Typography>
					{/* <div className="Preus-separator"></div> */}
					<Box className="Preus-content" sx={{display: 'block'}}>
						{/* <Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>Ballús, SA manté aquest lloc web amb l’objectiu de facilitar l’accés a informació corporativa i no corporativa actualitzada i exacta. Tractarem de corregir els errors que se’ns assenyali amb la màxima celeritat possible. No obstant, Ballús, SA no pot garantir la inexistència d’errors ni que el contingut de la informació es trobi permanentment actualitzat.</Typography>
                        <Typography variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}>En conformitat a l’establert a les lleis 15/1999 (Protecció de Dades de Caràcter Personal) i 34/2002 (Serveis de la Societat de la Informació i del Comerç Electrònic) s’informa del següent:</Typography>
						 */}
					</Box>
				</Box>
                <Box className='Preus-white'>
                    
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            <Typography variant='body1' className="Preus-text" color='#1e1e1e !important' dangerouslySetInnerHTML={{__html: props.masia?.avis_content}}></Typography>
                            
                        </Box>
                    </Box>
                </Box>
			</Box>
		</Page>
	);
}

export default Avis;