import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Page from "../../components/layouts/Page";
import { ReactComponent as Mail } from "../../assets/mail.svg";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Ubi } from "../../assets/ubi.svg";
import '../../css/views/public/Preus.css';
import image from '../../assets/img/BG.png';
import Footer from "../../layouts/public/Footer";
import Calendari from "../../components/elements/Calendari";
import FullCalendari from "../../components/elements/FullCalendari";

function Preus(props) {

	
	return (
		<Page title="El Pinatell">
			<Box className='Preus-portada'>
				<Box className='Preus-padding' padding={props.isMobile ? '15% 0' : '10% 20.6% 5%'}>
					<Typography variant="h2" className="Preus-title">{props.masia?.reserves_titol}</Typography>
					<div className="Preus-separator"></div>
					<Box className="Preus-content">
						<Typography dangerouslySetInnerHTML={{__html: props.masia?.reserves_text}} variant="body1" className="Preus-text" maxWidth={props.isMobile ? '100%': '50%'}></Typography>
						
					</Box>
				</Box>
                <Box className='Preus-white'>
                    <Box className='Preus-padding'>
                        <Box className='Preus-flex'>
                            <Box className='Preus-reserves'>
                                <Typography variant="h3" className="Preus-subtitle" marginBottom='30px'>Reserves</Typography>
                                {/* <Calendari /> */}
                                <FullCalendari />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='Preus-padding' sx={{maxWidth: props.isMobile ? '100%': 'unset'}}>
                        <Box className='Preus-blue'>

                            <Typography variant="h3" className="Preus-subtitle" color='#fff !important' marginBottom='30px'>{props.masia?.reserves_subtitol}</Typography>
                            <Typography dangerouslySetInnerHTML={{__html: props.masia?.reserves_subtext}} variant="body1" className="Preus-text" color='#fff !important' marginBottom='10px'></Typography>
                            <div className="Preus-line white"></div>
                            <Box className='Preus-flex'>
                                <Box className='Preus-llistat'>
                                    <Typography variant="h5" className="Preus-text" color='#fff'>Preus per a caps de setmana</Typography>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '88%'}}>
                                        <div>
                                            <div className="Preus-line white"></div>
                                            <div className="Preus-flex" style={{flexDirection: 'unset'}}>
                                                <Typography variant="body1" className="Preus-text" color='#fff'>Preu a consultar</Typography>
                                            </div>                                  
                                            <div className="Preus-line white"></div>
                                            <div className="Preus-flex" style={{flexDirection: 'unset'}}>
                                                <Typography variant="body1" className="Preus-text" color='#fff'>Ponts i festius, preus a consultar</Typography>
                                            </div>                                  
                                            <div className="Preus-line white"></div>
                                        </div>
                                    </div>
                                </Box>
                                <Box className='Preus-llistat'>
                                    <Typography variant="h5" className="Preus-text" color='#fff'>Preus per a setmana complerta</Typography>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '88%'}}>
                                        <div>
                                            <div className="Preus-line white"></div>
                                            <div className="Preus-flex" style={{flexDirection: 'unset'}}>
                                                <Typography variant="body1" className="Preus-text" color='#fff'>Preu a consultar</Typography>
                                            </div>                                    
                                            <div className="Preus-line white"></div>
                                        <Typography variant="body1" className="Preus-text" color='#fff'>Els preus inclouen IVA</Typography>
                                        <Typography variant="body1" className="Preus-text" color='#fff'>La taxa turística no està inclosa en el preu</Typography>
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='Preus-padding'>
                        <Box className='Preus-condicions'>
                            <Typography variant="h3" className="Preus-subtitle" marginBottom='30px'>Condicions de les reserves</Typography>
                            {props.masia?.condicions.map((condicio) => {
                                return (
                                    <div>
                                        <Typography variant="h6" className="Preus-text" fontWeight='bold' color='#1e1e1e !important'>{condicio.titol}</Typography>
                                        {condicio?.items.map((item) => {
                                            return (
                                                <Typography variant='body1' className="Preus-text" color='#1e1e1e !important'>{item.text}</Typography>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
			</Box>
            <Footer isMobile={props.isMobile}/>
		</Page>
	);
}

export default Preus;