import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import theme from "../../theme";
import '../../index.css';
// Asegúrate de ajustar la ruta de importación a donde se encuentre tu theme.

export default function RichText(props) {
  const editorRef = useRef(null);

  // Generamos la cadena CSS usando las propiedades definidas en el theme
  const contentStyle = `
    /* Encabezados */
    h1 {
      font-size: ${theme.typography.h1.fontSize};
      font-family: ${theme.typography.h1.fontFamily};
      color: ${theme.typography.h1.color};
      line-height: ${theme.typography.h1.lineHeight || "initial"};
      text-transform: ${theme.typography.h1.textTransform || "none"};
    }
    h2 {
      font-size: ${theme.typography.h2.fontSize};
      font-family: ${theme.typography.h2.fontFamily};
      color: ${theme.typography.h2.color};
      line-height: ${theme.typography.h2.lineHeight || "initial"};
      text-transform: ${theme.typography.h2.textTransform || "none"};
      font-weight: ${theme.typography.h2.fontWeight || "normal"};
    }
    h3 {
      font-size: ${theme.typography.h3.fontSize};
      font-family: ${theme.typography.h3.fontFamily};
      color: ${theme.typography.h3.color};
      text-transform: ${theme.typography.h3.textTransform || "none"};
    }
    h4 {
      font-size: ${theme.typography.h4.fontSize};
      font-family: ${theme.typography.h4.fontFamily};
      color: ${theme.typography.h4.color};
      text-transform: ${theme.typography.h4.textTransform || "none"};
    }
    h5 {
      font-size: ${theme.typography.h5.fontSize};
      font-family: ${theme.typography.h5.fontFamily};
      color: ${theme.typography.h5.color};
      font-weight: ${theme.typography.h5.fontWeight || "normal"};
    }
    h6 {
      font-size: ${theme.typography.h6.fontSize};
      font-family: ${theme.typography.h6.fontFamily};
      color: ${theme.typography.h6.color};
      font-weight: ${theme.typography.h6.fontWeight || "normal"};
      text-transform: ${theme.typography.h6.textTransform || "none"};
    }

    /* Párrafos (body1) */
    p, body {
      font-size: ${theme.typography.body1.fontSize};
      font-family: ${theme.typography.body1.fontFamily};
      color: ${theme.typography.body1.color};
    }
  `;

  const onChange = () => {
    props.f_setData(editorRef.current.getContent());
  };

  return (
    <>
    <div className="custom-tinymce-wrapper" style={{fontSize: '14px !important'}}>
      <Editor
        apiKey="qeadn68y0w1p8m8z7fahdl0o7xlac603a1cm049n85yd1gkb" // Reemplaza con tu API key
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={props.initialValue}
        init={{
          height: 500,
          preview_styles: false,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | link | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
          content_style: contentStyle,
        }}
        onChange={onChange}
      />
      </div>
    </>
  );
}
